import { defineComponent } from 'vue';
import GreenArrows from '../../assets/svg/green-arrows.svg';
export default defineComponent({
    name: 'JobCheck',
    components: { GreenArrows },
    props: { imgCorner: String },
    setup() {
        return { GreenArrows };
    },
});
